import { Box, Icon, TextField, Typography } from "@mui/material";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useMemo, useState } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Row } from "./MarketPlacePairsRow";
// import { CentrifugeContext } from "providers/CentrifugeProvider";

export const MarketPlacePairs = () => {
  const [search, setSearch] = useState<string>("");
  const currencies = useTypedSelector((state) => state.exchange.currencies);
  // const socketContext = useContext(CentrifugeContext);

  const usdtCurrencies = useMemo(() => {
    return currencies?.result.list.filter((item) => item.quoteCoin === "USDT");
  }, [currencies]);

  //UI
  const resultCurrencies = useMemo(() => {
    if (!currencies) return null;
    if (!search) return usdtCurrencies;

    return usdtCurrencies?.reduce((acc: any, currency) => {
      if (currency.symbol.toLowerCase().includes(search.toLowerCase().trim())) {
        acc.push(currency);
      }
      return acc;
    }, []);
  }, [search, usdtCurrencies]);

  return (
    <Box width={317} mx={20 / 8} my={30 / 8}>
      <Box>
        <TextField
          fullWidth
          variant={"standard"}
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          sx={{
            ".MuiInput-root": {
              paddingBottom: "5px",
            },
          }}
          InputProps={{
            startAdornment: (
              <Icon>
                <img src="/images/exchange/magnify.svg" alt="Magnidy" />
              </Icon>
            ),
          }}
        ></TextField>
      </Box>

      <Box mt={4}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={1.5}
        >
          <Typography variant={"caption"} sx={{ color: "#52555E" }}>
            Asset
          </Typography>

          <Typography variant={"caption"} sx={{ color: "#52555E" }}>
            Last price
          </Typography>
        </Box>

        <Box style={{ height: "calc(100vh - 240px)" }}>
          {resultCurrencies && (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  className="List"
                  height={height}
                  width={width}
                  itemCount={resultCurrencies.length}
                  itemData={resultCurrencies}
                  itemSize={54}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          )}
        </Box>
      </Box>
    </Box>
  );
};
