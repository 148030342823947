import { useEffect, useState, useContext, useMemo } from "react";

import { AdditionalLayout } from "components";
import { useNavigate } from "react-router-dom";
import { getCoinPath } from "utils/common.utils";

// import AccountCircle from "@mui/icons-material/AccountCircle";
import { Box, styled } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from '@mui/icons-material/Search';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  setSelectedPair,
  setSelectedPairId,
  addPairChangingCounter,
} from "../../store/exchange/exchange.slice";
import {
  getMarketThunk,
  currenciesGetThunk,
} from "../../store/exchange/exchange.thunk";
import {
  StyledButton,
  StyledCoinIcon,
  StyledPercent,
  StyledSearchIcon,
  StyledShortName,
  StyledSubWrapper,
  StyledTableCell,
  StyledTableCellHeadText,
  StyledTableCellThin,
  StyledTableRow,
  StyledTextField,
  StyledTitle,
  StyledWrapper,
} from "./Markets.styles";

import { IMarket, LinearPerpetual } from "types/exchange.types";
import { CentrifugeContext } from "providers/CentrifugeProvider";
import { ByBitTickerData } from "providers/ByBitProvider";
import { withByBitTicker } from "components/ByBitTickerHOC";
import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon";

const StyledFieldWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: 83,
}));

const Markets = () => {
  const [search, setSearch] = useState<string>("");
  // const [marketsList, setMarketsList] = useState<LinearPerpetual[]>([]);
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { isExchangeLoading, currencies } = useTypedSelector(
    (state) => state.exchange
  );

  const usdtCurrencies = useMemo(() => {
    return currencies?.result.list.filter((item) => item.quoteCoin === "USDT");
  }, [currencies]);

  const resultCurrencies = useMemo(() => {
    if (!usdtCurrencies) return [];
    if (!search) return usdtCurrencies;
    return usdtCurrencies.filter((currency) =>
      currency.symbol.toLowerCase().includes(search.toLowerCase().trim())
    );
  }, [usdtCurrencies, search]);

  const setSelectedPairForTrade = (pair: string) => {
    const pairBody = currencies?.result.list.find(
      (currency) => currency.symbol === pair && currency.quoteCoin === "USDT"
    );

    if (pairBody) {
      // dispatch(setSelectedPairId(Number(pairBody.symbol)));
      dispatch(setSelectedPair(pairBody));
      dispatch(addPairChangingCounter());
      nav(`/exchange`);
    }
  };

  return (
    <AdditionalLayout hideMenu style={{ background: "black" }}>
      <StyledWrapper>
        <StyledSubWrapper>
          <StyledTitle>Markets</StyledTitle>
          <StyledFieldWrapper display="flex" justifyContent="center">
            <StyledTextField
              onChange={(e) => setSearch(e.target.value)}
              id="input-with-icon-textfield"
              startAdornment={
                <InputAdornment position="start">
                  <StyledSearchIcon />
                </InputAdornment>
              }
              placeholder="Search for"
            />
          </StyledFieldWrapper>

          <TableContainer
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Table
              sx={{ minWidth: 650, maxWidth: 1040 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ display: "flex", justifyContent: "start" }}
                  >
                    <StyledTableCellHeadText>Coin</StyledTableCellHeadText>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {" "}
                    <StyledTableCellHeadText>
                      Last Price ($)
                    </StyledTableCellHeadText>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellHeadText>24H %</StyledTableCellHeadText>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellHeadText>
                      24H Volume
                    </StyledTableCellHeadText>
                  </TableCell>
                  {/* <TableCell>
                    <StyledTableCellHeadText>
                      24H Volume
                    </StyledTableCellHeadText>
                  </TableCell> */}
                  {/* <TableCell>
                    <StyledTableCellHeadText>24H graph</StyledTableCellHeadText>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultCurrencies &&
                  resultCurrencies.map((row, index) => (
                    <RowWithTicker
                      key={index}
                      data={row}
                      onSelect={setSelectedPairForTrade}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* {items.map((item, index) => (
            <MarketItem item={item} key={index} />
          ))} */}
        </StyledSubWrapper>
      </StyledWrapper>
    </AdditionalLayout>
  );
};

const Row = ({
  value: row,
  onSelect,
}: {
  value: LinearPerpetual & ByBitTickerData;
  onSelect: (symbol: string) => void;
}) => {
  return (
    <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <StyledTableCell
        align="center"
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 17,
        }}
      >
        <CoinIcon coinName={row.baseCoin?.toLowerCase()} style={{ width: 30 }} />
        <StyledShortName>{row.baseCoin}</StyledShortName>
      </StyledTableCell>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="left" style={{ color: "black" }}>
        ${row.lastPrice}
      </StyledTableCell>
      <StyledTableCell align="left">
        <StyledPercent isPositive={Number(row.price24hPcnt) >= 0}>
          {+row.price24hPcnt > 0 ? "+" : ""}{" "}
          {(+row.price24hPcnt * 100).toFixed(4)} %
        </StyledPercent>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ color: "black" }}>
        ${row.volume24h}
      </StyledTableCell>
      {/* <StyledTableCellThin align="left">
€{Number(row.volume24HEUR).toLocaleString()}
</StyledTableCellThin> */}
      {/* <StyledTableCellThin align="center">
  <StyledImg src={"/images/marketGraph.svg"} />
</StyledTableCellThin> */}
      <StyledTableCell align="center">
        <StyledButton
          variant="outlined"
          color="info"
          style={{ borderWidth: 2 }}
          onClick={() => onSelect(row.symbol)}
        >
          Trade
        </StyledButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const RowWithTicker = withByBitTicker(Row);

export default Markets;
